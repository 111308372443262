import Vue from 'vue';

import '../About/About.scss';
import Footer from '@/components/Shared/Footer/Footer';
import ClientNav from '@/components/Clients/ClientNav';

const Story = Vue.extend({
  render() {
    return (
      <div>
        <ClientNav />

        <div class="about">

          <img data-src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663628/Teefal%20Studios/3O2A1687.jpg' class="lazyload about__image" alt="Our story" />

          <div>
            <div class="about__content">
              <h2 class="about__title">Story</h2>
              <p class="about__description">Okay, here you can write a whole history of your brand or make a post about recent collection, collaboration or event. The most simple and elegant way to make people interested in what you do – tell them a good story. Ofcorse good story needs a good design, but you don’t need to worry – we’ve already dealed with it. Just try to remember all the fun, add a few photos or sliders and here it is!</p>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    );
  },
});

export default Story;
